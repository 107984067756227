export const FEATURED_COUNTRIES = [
  {
    id: "1",
    href: "/vakantiehuizen?country=NL&location=Nederland",
    name: "Nederland",
    taxonomy: "countries",
    count: "2700+",
    thumbnail:
      "/assets/img/homepage-featured-regions/topvakantiehuizen-nederland.jpg",
  },
  {
    id: "222",
    href: "/vakantiehuizen?country=FR&location=Frankrijk",
    name: "Frankrijk",
    taxonomy: "countries",
    count: "2800+",
    thumbnail:
      "/assets/img/homepage-featured-regions/topvakantiehuizen-frankrijk.jpg",
  },
  {
    id: "3",
    href: "/vakantiehuizen?country=ES&location=Spanje",
    name: "Spanje",
    taxonomy: "countries",
    count: "1400+",
    thumbnail:
      "/assets/img/homepage-featured-regions/topvakantiehuizen-spanje.jpg",
  },
  {
    id: "4",
    href: "/vakantiehuizen?country=AT&location=Oostenrijk",
    name: "Oostenrijk",
    taxonomy: "countries",
    count: "2200+",
    thumbnail:
      "/assets/img/homepage-featured-regions/topvakantiehuizen-oostenrijk.jpg",
  },
  {
    id: "5",
    href: "/vakantiehuizen?country=BE&location=België",
    name: "België",
    taxonomy: "countries",
    count: "1300+",
    thumbnail:
      "/assets/img/homepage-featured-regions/topvakantiehuizen-belgie.jpg",
  },
  {
    id: "5",
    href: "/vakantiehuizen?country=DE&location=Duitsland",
    name: "Duitsland",
    taxonomy: "countries",
    count: "3800+",
    thumbnail:
      "/assets/img/homepage-featured-regions/topvakantiehuizen-duitsland.jpg",
  },
];
