import { BuilderComponent, builder } from "@builder.io/react";
import Head from "next/head";
import { NextSeo } from "next-seo";
import { Header } from "../components/headers/Header";
import BackgroundGlassmorphism from "../components/backgrounds/BackgroundGlassmorphism";
import SectionHero from "../components/section-hero/SectionHero";
import FeaturedItemsSlider from "../components/featured-items-slider/FeaturedItemsSlider";
import BackgroundSection from "../components/backgrounds/BackgroundSection";
import { FEATURED_COUNTRIES } from "../data/featured-countries";
import ExploreNearby from "../components/explore-nearby/ExploreNearby";
import Footer from "../components/footer/Footer";

// Replace with your Public API Key.
builder.init(process.env.NEXT_PUBLIC_BUILDER_PUBLIC_KEY);

export async function getStaticProps({ params }) {
  const homepage = await builder.get("homepage").toPromise();

  return {
    props: {
      homepage: homepage || null,
    },
  };
}

export default function Homepage({ homepage }) {
  const { data } = homepage;
  const { seo } = data;
  const { title, description } = seo;
  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        {!homepage && <meta name="robots" content="noindex" />}
      </Head>
      <div className="nc-PageHome relative overflow-hidden">
        <Head>
          <meta name="robots" content="all" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, shrink-to-fit=no"
          />
          <meta
            name="msvalidate.01"
            content="B2F4D3BE7067960B02415D3CAF9AC7F3"
          />
        </Head>
        <NextSeo
          title={"De vakantiehuizen website van Europa | TopVakantiehuizen.eu"}
          description={
            "Op zoek naar een vakantiehuis in Europa? Op TopVakantiehuizen.eu vind jij het perfecte vakantiehuis! Boek veilig & snel bij TopVakantiehuizen.eu!"
          }
          canonical={`https://www.topvakantiehuizen.eu/`}
          openGraph={{
            title,
            description,
            url: `https://www.topvakantiehuizen.eu/`,
            images: [
              {
                url: "https://www.topvakantiehuizen.eu/assets/logo/TopVakantiehuizen.eu.png",
                width: 1324,
                height: 927,
                alt: "Logo van TopVakantiehuizen.eu",
                type: "image/png",
              },
            ],
            site_name: "TopVakantiehuizen.eu",
          }}
          twitter={{
            handle: "@handle",
            site: "@site",
            cardType: "summary_large_image",
          }}
        />
        <Header />
        <BackgroundGlassmorphism />
        <main className="container relative mb-24 space-y-24 lg:mb-32 lg:space-y-32">
          <SectionHero />
          <FeaturedItemsSlider
            uniqueId="listing-nearby"
            heading="Vakantie bestemmingen"
            subHeading="De mooiste bestemmingen in Nederland"
            categoryCardType="card3"
            itemPerRow={5}
          />
          <div className="relative py-16">
            <BackgroundSection className="bg-orange-50 dark:bg-black dark:bg-opacity-20 " />
            <FeaturedItemsSlider
              uniqueId="listing-suggestions"
              countries={FEATURED_COUNTRIES}
              categoryCardType="card4"
              itemPerRow={4}
              heading="Populaire bestemmingen"
              subHeading="Populaire vakantiebestemmingen binnen Europa"
              sliderStyle="alignCenter"
            />
          </div>
          <ExploreNearby />

          <BuilderComponent model="homepage" content={homepage} />
        </main>
        <Footer />
      </div>
    </>
  );
}
