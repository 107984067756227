import React from "react";
import Image from "next/image";
import ButtonPrimary from "../shared/button/ButtonPrimary";
import HeroSearchForm from "../hero-search-form/HeroSearchForm";

const SectionHero = ({
  title = "Vakantiehuizen in heel Europa",
  description = "Van een prachtige villa aan het strand tot een authentieke chalet in de bergen.",
  buttonText = "Vakantiehuizen bekijken",
  buttonLink = "/vakantiehuizen",
  img = "/assets/img/hero-right/topvakantiehuizen-hero.webp",
}) => {
  return (
    <div
      className={`nc-SectionHero relative flex flex-col-reverse lg:flex-col pt-10 pb-16 lg:pt-20`}
      data-nc-id="SectionHero"
    >
      <div className="flex flex-col lg:flex-row lg:items-center">
        <div className="flex flex-shrink-0 flex-col items-start space-y-8 pb-14 sm:space-y-10 lg:mr-10 lg:w-1/2 lg:pb-64 xl:mr-0 xl:pr-14">
          <h2 className="text-4xl font-medium !leading-[114%] md:text-5xl xl:text-7xl ">
            {title}
          </h2>
          <span className="text-base text-neutral-500 dark:text-neutral-400 md:text-lg">
            {description}
          </span>
          <ButtonPrimary href={buttonLink}>{buttonText}</ButtonPrimary>
        </div>
        <div className="flex-grow">
          <Image
            className="w-full"
            width={1000}
            height={1000}
            src={img}
            alt="TopVakantiehuizen hero"
            priority
            placeholder="blur"
            blurDataURL={img}
          />
        </div>
      </div>

      <div className="z-10 mb-12 w-full lg:mb-0 lg:-mt-40">
        <HeroSearchForm />
      </div>
    </div>
  );
};

export default SectionHero;
